import { Injectable } from '@angular/core';
import { PropertyAlertsApi } from '@core/data-access/apis/property-alerts.api';

@Injectable()
export class PropertyAlertsFacade {
	constructor(private propertyAlert: PropertyAlertsApi) {}

	getPropertyAlerts(filter) {
		return this.propertyAlert.getPropertyAlerts(filter);
	}

	addAlert(body) {
		return this.propertyAlert.addAlert(body);
	}

	deleteAlert(alertId) {
		return this.propertyAlert.deletePropertyAlert(alertId);
	}
}
