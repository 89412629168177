import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@core/constants';
import { FilterType } from '@core/enums';
import { CommonUtil } from '@core/utils/common.util';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root',
})
export class PropertyAlertsApi {
	constructor(private http: HttpClient) {}

	readonly featureVersion = environment.featureVersion;
	readonly filterType = FilterType;

	getPropertyAlerts(body) {
		return this.http.get(API.propertyAlerts.getAlerts, {
			params: CommonUtil.createHttpParams(body),
		});
	}

	addAlert(body) {
		return this.http.post(API.propertyAlerts.base, body);
	}

	deletePropertyAlert(alertId) {
		return this.http.delete(API.propertyAlerts.deleteAlert(alertId));
	}
}
